<template>
    <div style="overflow-x: hidden" class="act-form shift-closing-act-form" v-if="act">
        <error-list :errors="errors" :message-function="errorMessage" @clear="errors = []"></error-list>

        <div class="card">
            <div class="card-header">
                <span v-if="act.storage">
                    {{ act.storage.name }}
                </span>
                <span v-if="act.productionShift || act.date">
                    ({{ act.date ? moment(act.date).format('DD.MM.YY') : '' }}<template v-if="act.date && act.productionShift"> / </template>{{ act.productionShift ? act.productionShift.name : '' }})
                </span>
            </div>

            <div class="card-body">
                <div class="row" style="padding-top: 20px;">
                    <div class="col-sm-4">
                        <b-form-group description="Цех">
                            <storage-picker selectable-for-task-list :allowed-roles="storageAllowedRoles" v-model="act.storage" :disabled="!!act.blocked || !!act.id"></storage-picker>
                        </b-form-group>
                    </div>
                <div class="col-sm-8">
                    <actual-shift-picker :disabled="!!act.blocked || !this.act.storage || !!act.id" :storage="act.storage" v-model="act.actualShift"></actual-shift-picker>
                </div>
                </div>
            </div>
            <div v-if="act.storage && act.actualShift">
                <rests ref="rests" :act="act" :disabled="!!act.blocked"></rests>
            </div>

            <div class="card-body">
                <b-form-textarea v-model="act.comment" placeholder="Комментарий..." rows="6" :disabled="act.blocked"></b-form-textarea>
            </div>
        </div>

        <control-panel>
            <button class="btn btn-danger" @click="$router.go(-1);">Назад</button>
            <b-button v-if="!act.blocked" variant="success" @click="checkFulfillment()" v-show="act.id">Закрыть смену</b-button>
            <b-button variant="info" @click="save" v-show="!act.blocked">Сохранить</b-button>
        </control-panel>

        <users-of-the-act :act="act"></users-of-the-act>

        <modal
            name="shift_fulfillment"
            :adaptive="true"
            :scrollable="true"
            :height="'auto'"
            :maxHeight="300"
            :minWidth="900"
        >
            <fulfillment-info
                @fulfillment-confirmed="blockShift(); $modal.hide('shift_fulfillment')"
                :act="act"
            ></fulfillment-info>
        </modal>
    </div>
</template>

<script>

    import ControlPanel from '../_common/ControlPanel';
    import SuccessMessage from '../_common/SuccessMessage';
    import StoragePicker from '../_common/StoragePicker';
    import ActualShiftPicker from "../_common/ActualShiftPicker";
    import {ParseLaravelErrorsMixin} from '@mixins';
    import ErrorList from '../_common/ErrorList';
    import moment from 'moment';
    import FulfillmentInfo from './FulfillmentInfo';
    import {cloneDeep, groupBy, sumBy} from 'lodash';
    import BarcodeRepository from '../../utils/BarcodeRepository';
    import UsersOfTheAct from '../_common/UsersOfTheAct';
    import {SHIFT_CLOSING_ACT_ENDPOINT} from "../../utils/endpoints";
    import Rests from "./Wizard/Rests/Rests";
    import {ROLE_GROUP_SENIOR, ROLE_STORAGE_SENIOR} from "../../utils/Roles";

    export default {
        components: {
            ControlPanel,
            StoragePicker,
            ErrorList,
            SuccessMessage,
            UsersOfTheAct,
            FulfillmentInfo,
            ActualShiftPicker,
            Rests
        },
        mixins: [
            ParseLaravelErrorsMixin,
        ],
        computed: {
            currentActId() {
                return this.$route.params.id ? this.$route.params.id : null
            },
            storageAllowedRoles() {
                return [ROLE_GROUP_SENIOR, ROLE_STORAGE_SENIOR];
            },
        },
        watch: {
            currentActId() {
                this.resetData()
            },
            'act.actualShift'() {
                if (!!this.act.actualShift) {
                    this.act.date = moment(this.act.actualShift.date).format('YYYY-MM-DD');
                    this.act.productionShift = this.act.actualShift.shift;
                }
            },
        },
        data() {
            return {
                ROLE_STORAGE_SENIOR,
                act: null,
                errors: [],
                infoCollapsed: false,
                nomenclatureLots: [],
                report: [],
                successCountDown: 0,
                with: [
                    'storage',
                    'productionShift',
                    'items.nomenclature.measureUnit',
                    'items.nomenclatureLot',
                    'items.box',
                    'actualShift.shift'
                ],
            }
        },
        methods: {
            moment,
            resetData() {
                if (this.currentActId) {
                    this.fetchAct();
                } else {
                    this.setNewAct();
                }
            },
            fetchAct() {
                this.$http.get(this.getBaseUrl() + '/' + this.currentActId, {
                    params: {
                        with: this.with,
                    }
                })
                    .then(response => {
                        let act = response.data.data;

                        if (act.version === 1) {
                            let groupedItems = groupBy(act.items, 'nomenclature_id');
                            act.items = Object.keys(groupedItems).map(k => {
                                let itemGroup = groupedItems[k];
                                return {
                                    id: null,
                                    nomenclature: itemGroup[0].nomenclature,
                                    nomenclature_id: itemGroup[0].nomenclature_id,
                                    nomenclatureLot: null,
                                    nomenclature_lot_id: null,
                                    count: sumBy(itemGroup, 'count')
                                }
                            });
                        }
                        this.act = act;
                        this.act.date = moment(this.act.date).format('YYYY-MM-DD');
                    })
            },
            setNewAct() {
                let productionShift = null,
                    storage = null,
                    date = moment().format('YYYY-MM-DD')

                if (this.$route.params.storage) {
                    storage = cloneDeep(this.$route.params.storage)
                }
                if (this.$route.params.shift) {
                    productionShift = cloneDeep(this.$route.params.shift)
                }
                if (this.$route.params.date) {
                    date = moment(this.$route.params.date).format('YYYY-MM-DD')
                }

                this.act = {
                    productionShift,
                    storage,
                    items: [],
                    date: date,
                    blocked: false,
                    comment: null,
                    version: 2
                }
            },
            getBaseUrl() {
                return SHIFT_CLOSING_ACT_ENDPOINT;
            },
            save() {
                this.errors = [];

                let method = this.currentActId ? 'put' : 'post',
                    url = this.getBaseUrl() + (this.currentActId ? `/${this.currentActId}` : ''),
                    data = this.getDataForSave()

                this.$http[method](url, data)
                    .then(response => {
                        this.$toast.succes('Сохранено');

                        if (!this.currentActId) {
                            this.$router.push({name: 'ShiftClosingActUpdate', params: {id: response.data.id}})
                        }
                    })
                    .catch(response => {
                        if (!!response.data && !!response.data.message) {
                            this.$toast.error(response.data.message);
                        } else if (response.status !== 0) {
                            console.log(response);
                            this.$toast.error('Ошиюка!');
                        }
                    });
            },
            getDataForSave() {

                let data = {
                    blocked: !!this.act.blocked ? 1 : 0,
                    canceled: !!this.act.canceled ? 1 : 0,
                    comment: this.act.comment,
                    date: !!this.act.date ? moment(this.act.date).format('YYYY-MM-DD') : null,
                    production_shift_id: !!this.act.productionShift ? this.act.productionShift.id : null,
                    storage_id: !!this.act.storage ? this.act.storage.id : null,
                    actual_shift_id: this.act.actualShift.id,

                    items: [],

                    with: this.with,
                };

                if (this.currentActId) {
                    data.id = this.currentActId;
                }

                this.$refs.rests.getItems().map(i => {
                    data.items.push({
                        id: i.id,
                        box: {name: BarcodeRepository.boxPrefixes[1] + Math.floor(Math.random() * (99999 - 10000 + 1) + 10000).toString()},
                        count: parseFloat(i.count),
                        nomenclature: i.nomenclature,
                        nomenclature_id: i.nomenclature.id,
                        nomenclature_lot_id: null
                    });
                });

                return data;
            },
            toggleAct(blocked) {
                this.$http.put(`${this.getBaseUrl()}/${this.currentActId}/block`, {
                        id: this.act.id,
                        blocked: blocked
                    })
                    .then(response => {
                        this.act.blocked = response.data.blocked;
                    })
                    .catch(error => {
                        this.errors = this.parseErrors(error, this.errors);
                    })
            },
            blockShift() {
                if (!window.confirm("Закрыть смену?\nПовторное открытие смены невозможно")) {
                    return;
                }

                this.toggleAct(true);
            },
            selectItem(item, forceOpen = false) {
                this.act.items.map(i => this.$set(i, 'variant', 'default'))
                this.$set(item, 'variant', 'secondary')
                this.$set(item, 'collapsed', forceOpen || item.collapsed !== true)
            },
            errorMessage(error) {
                if (typeof error === 'string') {
                    return error
                }

                let matchResult = error.propertyPath.match(/^items\.(\d+)\./),
                    message = error.message;

                if (matchResult) {
                    if (!!this.act.items[matchResult[1]] && !!this.act.items[matchResult[1]].nomenclature) {
                        message = `${this.act.items[matchResult[1]].nomenclature.name}: ${message}`
                    } else {
                        message = `Элемент ${matchResult[1]}: ${message}`;
                    }
                }

                return message
            },
            checkFulfillment() {
                this.$modal.show('shift_fulfillment');
            }
        },
        mounted() {
            this.resetData();
        }
    }
</script>

<style>
    .shift-closing-act-form input, .shift-closing-act-form select {
        font-size: 14px;
    }

    .shift-closing-act-form .card.bg-secondary {
        color: white !important;
    }

    .shift-closing-act-form .card.bg-secondary .text-muted {
        color: white !important;
    }

    .shift-closing-act-form .valid-item {
        background: #28a745;
        border-radius: 5px;
        padding: 0 2px;
        color: white;
    }

    .shift-closing-act-form .not-valid-item {
        background: rgba(236, 46, 64, 0.91);
        border-radius: 5px;
        padding: 0 2px;
        color: white;
    }

    .shift-closing-act-form .multiselect__content-wrapper {
        position: relative;
    }

    .shift-closing-act-form .nav-tabs .nav-item {
        font-size: smaller;
    }

    .shift-closing-act-form .input-group-text {
        padding: 0.28rem 0.75rem;
    }
</style>
