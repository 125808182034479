<template>
    <b-card no-body class="mb-0">
        <b-card-header style="font-weight: bold;">
            <span>Проверка статуса смены</span>
            <span class="pull-right">
                        <button @click="$modal.hide('shift_fulfillment')" class="btn btn-sm btn-default"><i class="fas fa-times"></i></button>
                    </span>
        </b-card-header>
        <b-card-body>
            <div v-if="loading" class="text-center"><i class="fas fa-spin fa-spinner"></i></div>
            <div v-else>
                <div v-if="!prevClosed" class="text-center">
                    <div class="alert alert-danger">☠ Предыдущая смена не закрыта</div>
                </div>
                <div v-if="!started" class="text-center">
                    <div class="alert alert-danger">☠ Смена еще не началась</div>
                </div>

                <tasks class="mt-1 mb-2" v-if="act && actualShift && storage" :act="act" :actual-shift="actualShift" :storage="storage"/>

                <transfers class="mt-1 mb-2" v-if="act && actualShift && storage" :act="act" :actual-shift="actualShift" :storage="storage"/>
            </div>

            <div v-if="!loading">
                <button @click="$emit('fulfillment-confirmed')"
                        :class="{'btn-success': success, 'btn-warning': warning}"
                        class="pull-right btn">Закрыть смену</button>
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
    import {map, findIndex} from 'lodash';
    import moment from 'moment';
    import {PRODUCTION_ACTS_ENDPOINT, SHIFT_CLOSING_ACT_ENDPOINT} from "../../utils/endpoints";
    import Tasks from "./Wizard/Tasks/Tasks"
    import Transfers from "./Wizard/Transfers/Transfers"

    export default {
        props: {
            act: {
                type: Object,
                required: true
            }
        },
        components: {
            Tasks,
            Transfers
        },
        data () {
            return {
                loading: false,
                fulfilled: false,
                tasks: [],
                transfers: [],
                selectedTasks: {},
                prevClosed: false,
                started: false,
                actualShift: null,
                storage: null
            };
        },
        computed: {
            success() {
                return this.started && this.prevClosed && !this.tasks.length && !this.transfers.length;
            },
            warning() {
                return !this.success;
            }
        },
        methods: {
            map,
            moment,
            toggleSelectTask(task) {
                if (this.taskSelected(task)) {
                    this.$delete(this.selectedTasks, task.id);
                } else {
                    if (this.taskSelectable(task)) {
                        this.$set(this.selectedTasks, task.id, task);
                    }
                }
            },
            taskSelectable(task) {
                let firstSelectedId = Object.keys(this.selectedTasks).length ? Object.keys(this.selectedTasks)[0] : -1;
                if (firstSelectedId === -1) {
                    return task.productionActs.length === 0;
                }

                let firstSelectedTask = this.selectedTasks[firstSelectedId];

                let storagesAreEqual = true;

                if (!!firstSelectedTask.parent) {
                    if (!!task.parent) {
                        // Если у обеих тасок есть парент, их склады должны совпадать
                        storagesAreEqual = task.parent.storage_id === firstSelectedTask.parent.storage_id;
                    } else {
                        // У первой выбранной таски нет парента, значит и у этой не должно быть
                        storagesAreEqual = false;
                    }
                } else {
                    if (!!task.parent) {
                        storagesAreEqual = false;
                    }
                }

                if (task.productionActs.length === 0
                    && firstSelectedTask.nomenclature_id === task.nomenclature_id
                    && storagesAreEqual
                ) {
                    return true;
                }

                return false;
            },
            taskSelected(task) {
                return !! this.selectedTasks[task.id];
            },
            checkFulfillment() {

                if (this.loading) {
                    return;
                }

                this.loading = true;

                this.$http.get(SHIFT_CLOSING_ACT_ENDPOINT + '/check_fulfillment', {
                    params: {
                        shift_id: this.act.productionShift.id,
                        date: this.act.date,
                        storage_id: this.act.storage.id,

                        with: {
                            tasks: [
                                'productionActs',
                                'nomenclature',
                                'operation',
                                'stuff',
                                'parent'
                            ],
                            transfers: [
                                'items.nomenclatureLot.nomenclature'
                            ]
                        },

                        without_loading: true,
                    }
                }).then( response => {
                    this.fulfilled = response.data.fulfilled;
                    this.tasks = response.data.tasks;
                    this.transfers = response.data.transfers;
                    this.prevClosed = response.data.prevClosed;
                    this.started = response.data.started;
                    this.actualShift = response.data.actualShift;
                    this.storage = response.data.storage;
                }).catch( response => {
                    alert('Ошибка получения статуса смены');
                }).finally( () => {
                    this.loading = false;
                });
            },
            appendRawMaterial(task, result) {
                task.stuff.map(s => {
                    let index = findIndex(result, m => m.nomenclature.id === s.nomenclature_id);
                    if (index === -1) {
                        result.push({
                            nomenclature: {id: s.nomenclature_id},
                            count: parseFloat(s.count)
                        });
                    } else {
                        result[index].count += parseFloat(s.count);
                    }
                })
            },
            createProductionAct(task) {

                if (!window.confirm('Начать выпуск?')) {
                    return;
                }

                let count = 0;
                let rawMaterials = [];
                let tasks = [];

                if (Object.keys(this.selectedTasks).length) {
                    Object.keys(this.selectedTasks).map(k => {
                        this.appendRawMaterial(this.selectedTasks[k], rawMaterials);
                        count += parseFloat(this.selectedTasks[k].count);
                        tasks.push(this.selectedTasks[k]);
                    })
                } else {
                    this.appendRawMaterial(task, rawMaterials);
                    count = parseFloat(task.count);
                    tasks.push(task);
                }

                this.$http.post(PRODUCTION_ACTS_ENDPOINT, {
                    start_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                    shift: {id: this.act.productionShift.id},
                    nomenclature: {id: task.nomenclature_id},
                    storage: {id: task.operation.storage_id},
                    rawMaterials: rawMaterials,
                    tasks: tasks,
                    use_proportional_raw_materials: false
                }).then( response => {
                    this.selectedTasks = {};
                    this.checkFulfillment();
                }).catch(response => {

                })
            },
        },
        mounted() {
            console.log('mounted');
            this.checkFulfillment();
        }
    }
</script>
